//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import pageMixin from '$mixins/page-mixin.js';
import StepLayout from '../cuota-personalizada/steps/step-layout.vue';
import panaImg from '$resources/images/pana.png';

export default {
  name: 'Dashboard',
  components: {
    StepLayout
  },
  mixins: [pageMixin],
  data: () => ({
    panaImg
  }),
  methods: {
    onWhatsapp() {
      // window.open(`https://wa.me/+34868952028?text=${encodeURIComponent('la mejor tarifa')}`, '_blank');
    }
  }
};
